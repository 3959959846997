import React,{useState} from 'react'
import styled from "styled-components";

import { THEME_COLORS } from '../../../ThemeConfig.js'


const TermsAndConditions = () => {

    const [option,setOption] = useState(1)

  return (
    <Main>
        <Head>
            <Title>Terms of Services</Title>
            <Update>Updated January 30</Update>
        </Head>
        <Content>
            <Left>
                <TableHead>Table of Contents</TableHead>
                <TableItem className={option===1 && 'active'} onClick={()=>setOption(1)}>
                    Accepting the Terms
                </TableItem>
                <TableItem className={option===2 && 'active'} onClick={()=>setOption(2)}>
                    Accessing and correcting personal information
                </TableItem>
                <TableItem className={option===3 && 'active'} onClick={()=>setOption(3)}>
                    How we use cookies and collect information using technology.
                </TableItem>
                <TableItem className={option===4 && 'active'} onClick={()=>setOption(4)}>
                    what we do with the personal information we collect
                </TableItem>
                <TableItem className={option===5 && 'active'} onClick={()=>setOption(5)}>
                    When we dissolve personal information
                </TableItem>
                <TableItem className={option===6 && 'active'} onClick={()=>setOption(6)}>
                    Security
                </TableItem>
            </Left>
            <Right>
                {
                    option === 1 &&
                    <>
                    <SubTitle>Accepting the Terms</SubTitle>
                    <Description>Eget sit justo vitae suspendisse urna. Lacinia molestie elementum nibh 
                        vivamus arcu molestie enim praesent ac. Sed vitae dolor consectetur libero. 
                        Lacus commodo consectetur faucibus vitae at. Hac proin pellentesque tincidunt 
                        commodo morbi cursus congue. Vulputate mauris et at massa magna nibh. Faucibus 
                        egestas sed diam orci sollicitudin. Morbi morbi purus malesuada habitant non vitae sit eget.<br/>
                        Eget sit justo vitae suspendisse urna. Lacinia molestie elementum nibh vivamus arcu molestie enim praesent ac. Sed vitae dolor 
                    </Description>
                    <SubTitle>what we do with the personal information we collect</SubTitle>
                    <Description>Eget sit justo vitae suspendisse urna. Lacinia molestie elementum nibh 
                        vivamus arcu molestie enim praesent ac. Sed vitae dolor consectetur libero. Lacus 
                        commodo consectetur faucibus vitae at. Hac proin pellentesque tincidunt commodo 
                        morbi cursus congue. Vulputate mauris et at massa magna nibh. Faucibus egestas 
                        sed diam orci sollicitudin. Morbi morbi purus malesuada habitant non vitae sit eget.
                    </Description>
                    <SubTitle>Privacy and policies</SubTitle>
                    <Description>Eget sit justo vitae suspendisse urna. Lacinia molestie elementum nibh
                        vivamus arcu molestie enim praesent ac. Sed vitae dolor consectetur libero. 
                        Lacus commodo consectetur faucibus vitae at. Hac proin pellentesque tincidunt commodo
                        morbi cursus congue. Vulputate mauris et at massa magna nibh. Faucibus egestas sed 
                        diam orci sollicitudin. Morbi morbi purus malesuada habitant non vitae sit eget.
                    </Description>
                    <SubTitle>When we dissolve personal information</SubTitle>
                    <Description>Eget sit justo vitae suspendisse urna. Lacinia molestie elementum nibh
                        vivamus arcu molestie enim praesent ac. Sed vitae dolor consectetur libero. 
                        Lacus commodo consectetur faucibus vitae at. Hac proin pellentesque tincidunt commodo
                        morbi cursus congue. Vulputate mauris et at massa magna nibh. 
                    </Description>
                    </>
                }
                {
                    option === 2 &&
                    <>
                    <SubTitle>Accessing and correcting personal information</SubTitle>
                    <Description>Eget sit justo vitae suspendisse urna. Lacinia molestie elementum nibh 
                        vivamus arcu molestie enim praesent ac. Sed vitae dolor consectetur libero. 
                        Lacus commodo consectetur faucibus vitae at. Hac proin pellentesque tincidunt 
                        commodo morbi cursus congue. Vulputate mauris et at massa magna nibh. Faucibus 
                        egestas sed diam orci sollicitudin. Morbi morbi purus malesuada habitant non vitae sit eget.<br/>
                        Eget sit justo vitae suspendisse urna. Lacinia molestie elementum nibh vivamus arcu molestie enim praesent ac. Sed vitae dolor 
                    </Description>
                    </>
                }
                {                   
                    option === 3 &&
                    <>
                    <SubTitle>How we use cookies and collect information using technology.</SubTitle>
                    <Description>Eget sit justo vitae suspendisse urna. Lacinia molestie elementum nibh 
                        vivamus arcu molestie enim praesent ac. Sed vitae dolor consectetur libero. 
                        Lacus commodo consectetur faucibus vitae at. Hac proin pellentesque tincidunt 
                        commodo morbi cursus congue. Vulputate mauris et at massa magna nibh. Faucibus 
                        egestas sed diam orci sollicitudin. Morbi morbi purus malesuada habitant non vitae sit eget.<br/>
                        Eget sit justo vitae suspendisse urna. Lacinia molestie elementum nibh vivamus arcu molestie enim praesent ac. Sed vitae dolor 
                    </Description>
                    </>                   
                }
                {                   
                    option === 4 &&
                    <>
                    <SubTitle>what we do with the personal information we collect</SubTitle>
                    <Description>Eget sit justo vitae suspendisse urna. Lacinia molestie elementum nibh 
                        vivamus arcu molestie enim praesent ac. Sed vitae dolor consectetur libero. 
                        Lacus commodo consectetur faucibus vitae at. Hac proin pellentesque tincidunt 
                        commodo morbi cursus congue. Vulputate mauris et at massa magna nibh. Faucibus 
                        egestas sed diam orci sollicitudin. Morbi morbi purus malesuada habitant non vitae sit eget.<br/>
                        Eget sit justo vitae suspendisse urna. Lacinia molestie elementum nibh vivamus arcu molestie enim praesent ac. Sed vitae dolor 
                    </Description>
                    </>                   
                }
                {                   
                    option === 5 &&
                    <>
                    <SubTitle>When we dissolve personal information</SubTitle>
                    <Description>Eget sit justo vitae suspendisse urna. Lacinia molestie elementum nibh 
                        vivamus arcu molestie enim praesent ac. Sed vitae dolor consectetur libero. 
                        Lacus commodo consectetur faucibus vitae at. Hac proin pellentesque tincidunt 
                        commodo morbi cursus congue. Vulputate mauris et at massa magna nibh. Faucibus 
                        egestas sed diam orci sollicitudin. Morbi morbi purus malesuada habitant non vitae sit eget.<br/>
                        Eget sit justo vitae suspendisse urna. Lacinia molestie elementum nibh vivamus arcu molestie enim praesent ac. Sed vitae dolor 
                    </Description>
                    </>                   
                }
                {                   
                    option === 6 &&
                    <>
                    <SubTitle>Security</SubTitle>
                    <Description>Eget sit justo vitae suspendisse urna. Lacinia molestie elementum nibh 
                        vivamus arcu molestie enim praesent ac. Sed vitae dolor consectetur libero. 
                        Lacus commodo consectetur faucibus vitae at. Hac proin pellentesque tincidunt 
                        commodo morbi cursus congue. Vulputate mauris et at massa magna nibh. Faucibus 
                        egestas sed diam orci sollicitudin. Morbi morbi purus malesuada habitant non vitae sit eget.<br/>
                        Eget sit justo vitae suspendisse urna. Lacinia molestie elementum nibh vivamus arcu molestie enim praesent ac. Sed vitae dolor 
                    </Description>
                    </>                   
                }
            </Right>
        </Content>        
    </Main>
  )
}

export default TermsAndConditions

const Main = styled.div`
    width: 80%;
    margin: 0 auto;
    padding: 36px 0 100px;
    @media all and (max-width:1280px){
        width: 85%;
    }
`
const Head = styled.div`
    text-align: center;
`
const Title = styled.h4`
    font-weight: 700;
    font-size: 24px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 4px;
`
const Update = styled.p`
    font-weight: 500;
    font-size: 14px;
    color: ${THEME_COLORS.text_para};
`
const Content = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
`
const Left = styled.div`
    width: 25%;
    height: max-content;
    padding: 16px; 
    background-color: ${THEME_COLORS.white};
    box-shadow: ${THEME_COLORS.elevation_expert_e4};
    border-radius: 10px;
    @media all and (max-width:1280px){
        width: 30%;
    }
`
const TableHead = styled.h5`
    font-weight: 500;
    font-size: 20px;
    color: ${THEME_COLORS.black};
    margin-bottom: 12px;
`
const TableItem = styled.button`
    cursor: pointer;
    text-align: left !important;
    padding: 3px 0px;
    font-weight: 500;
    font-size: 14px;
    color: ${THEME_COLORS.dark_300};
    margin-bottom: 10px;
    &.active{
        color: ${THEME_COLORS.black};
        border-bottom: 2px solid ${THEME_COLORS.chips_blue_on_container_2};
    }
`
const Right = styled.div`
    width: 73%;
    min-height: 742px;
    padding: 32px 32px 32px 21px;
    border-radius: 10px;
    background-color: ${THEME_COLORS.white};
    box-shadow: ${THEME_COLORS.elevation_expert_e4};
    @media all and (max-width:1280px){
        width: 68%;
    }
`
const SubTitle = styled.h5`
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 16px;
    color: ${THEME_COLORS.text_title};
`
const Description = styled.p`
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 14px;
    color: ${THEME_COLORS.text_para};
`


